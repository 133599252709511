import React, { Component } from 'react';

const initData = {
    heading: "We are digital agency & Marketing",
    content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit nihil tenetur minus quidem est deserunt molestias accusamus harum ullam tempore debitis et, expedita, repellat delectus aspernatur neque itaque qui quod.",
    heroThumb_1: "/img/welcome_thumb_1.png",
    heroThumb_2: "/img/welcome_thumb_2.png",
    heroThumb_3: "/img/welcome_thumb_3.png",
    heroThumb_4: "/img/welcome_thumb_4.png",
    heroThumb_5: "/img/welcome_thumb_5.png",
    heroThumb_6: "/img/welcome_thumb_6.png",
    btnText_1: "Start a Project",
    btnText_2: "Contact Us"
}

class HeroOne extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: initData
        })
    }
    render() {
        return (
            <section id="home" className="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
                <div className="container">
                    <div className="row align-items-center">
                    {/* Welcome Intro Start */}
                    <div className="col-12 col-md-7">
                        <div className="welcome-intro">
                        <h1 className="text-white">{this.state.data.heading}</h1>
                        <p className="text-white my-4">{this.state.data.content}</p>
                        {/* Buttons */}
                        <div className="button-group">
                            <a href="/#" className="btn btn-bordered-white">{this.state.data.btnText_1}</a>
                            <a href="/#" className="btn btn-bordered-white d-none d-sm-inline-block">{this.state.data.btnText_2}</a>
                        </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-5">
                        {/* Welcome Thumb */}
                        <div className="welcome-thumb-wrapper mt-5 mt-md-0">
                        <span className="welcome-thumb-1">
                            <img className="welcome-animation d-block ml-auto" src={this.state.data.heroThumb_1} alt="" />
                        </span>
                        <span className="welcome-thumb-2">
                            <img className="welcome-animation d-block" src={this.state.data.heroThumb_2} alt="" />
                        </span>
                        <span className="welcome-thumb-3">
                            <img className="welcome-animation d-block" src={this.state.data.heroThumb_3} alt="" />
                        </span>
                        <span className="welcome-thumb-4">
                            <img className="welcome-animation d-block" src={this.state.data.heroThumb_4} alt="" />
                        </span>
                        <span className="welcome-thumb-5">
                            <img className="welcome-animation d-block" src={this.state.data.heroThumb_5} alt="" />
                        </span>
                        <span className="welcome-thumb-6">
                            <img className="welcome-animation d-block" src={this.state.data.heroThumb_6} alt="" />
                        </span>
                        </div>
                    </div>
                    </div>
                </div>
                {/* Shape Bottom */}
                <div className="shape shape-bottom">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" fill="#FFFFFF">
                    <path className="shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z" />
                    </svg>
                </div>
            </section>
        );
    }
}

export default HeroOne;