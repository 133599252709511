import React, { Component } from 'react';

const initData = {
    heading: "Case Studies",
    headingText_1: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.",
    headingText_2: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.",
    btnText: "View More"
}

const data = [
    {
        id: 1,
        image: "/img/case_studies_7.jpg",
        iconClass: "fas fa-search-plus fa-2x text-white",
        title: "Web Design",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta, eligendi pariatur corporis libero distinctio, corrupti."
    },
    {
        id: 2,
        image: "/img/case_studies_8.jpg",
        iconClass: "fas fa-search-plus fa-2x text-white",
        title: "App Development",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta, eligendi pariatur corporis libero distinctio, corrupti."
    },
    {
        id: 3,
        image: "/img/case_studies_9.jpg",
        iconClass: "fas fa-search-plus fa-2x text-white",
        title: "Data Analysis",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta, eligendi pariatur corporis libero distinctio, corrupti."
    },
    {
        id: 4,
        image: "/img/case_studies_10.jpg",
        iconClass: "fas fa-search-plus fa-2x text-white",
        title: "Content Management",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta, eligendi pariatur corporis libero distinctio, corrupti."
    },
    {
        id: 5,
        image: "/img/case_studies_11.jpg",
        iconClass: "fas fa-search-plus fa-2x text-white",
        title: "SEO Marketing",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta, eligendi pariatur corporis libero distinctio, corrupti."
    },
    {
        id: 6,
        image: "/img/case_studies_12.jpg",
        iconClass: "fas fa-search-plus fa-2x text-white",
        title: "Marketing Strategy",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta, eligendi pariatur corporis libero distinctio, corrupti."
    },
]

class PortfolioTwo extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section className="section case-studies ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2>{this.state.initData.heading}</h2>
                                <p className="d-none d-sm-block mt-4">{this.state.initData.headingText_1}</p>
                                <p className="d-block d-sm-none mt-4">{this.state.initData.headingText_2}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {this.state.data.map((item, idx) => {
                            return(
                                <div key={`pt_${idx}`} className="col-12 col-md-6 col-lg-4">
                                    {/* Single Case Studies */}
                                    <div className="single-case-studies">
                                        {/* Case Studies Thumb */}
                                        <a href="/#">
                                            <img src={item.image} alt="" />
                                        </a>
                                        {/* Case Studies Overlay */}
                                        <a href={item.image} data-fancybox="images" className="case-studies-overlay">
                                            {/* Overlay Text */}
                                            <span className="overlay-text text-center p-3">
                                                <i className={item.iconClass} />
                                                <h3 className="text-white mt-2 mb-3">{item.title}</h3>
                                                <p className="text-white">{item.text}</p>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="row justify-content-center">
                        <a href="/#" className="btn btn-bordered mt-4">{this.state.initData.btnText}</a>
                    </div>
                </div>
            </section>
        );
    }
}

export default PortfolioTwo;