import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/digimax-json/themeOneContentSection";

class ContentFive extends Component {
    state = {
        data: {},
        contentData_1: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    contentData_1: res.data.contentData_1
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section className="section content-area dark-bg ptb_150">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-12 col-lg-6">
                            {/* Profile Circle Wrapper */}
                            <div className="profile-circle-wrapper circle-animation d-none d-sm-block">
                                {/* Profile Inner */}
                                <div className="profile-inner">
                                    {/* Profile Circle */}
                                    <div className="profile-circle circle-lg">
                                        <span className="profile-icon icon-1">
                                            <img className="icon-1-img" src={this.state.data.profileIcon_1} alt="" />
                                        </span>
                                        <span className="profile-icon icon-2">
                                            <img className="icon-2-img" src={this.state.data.profileIcon_2} alt="" />
                                        </span>
                                        <span className="profile-icon icon-3">
                                            <img className="icon-3-img" src={this.state.data.profileIcon_1} alt="" />
                                        </span>
                                        <span className="profile-icon icon-4">
                                            <img className="icon-4-img" src={this.state.data.profileIcon_2} alt="" />
                                        </span>
                                    </div>
                                    {/* Profile Circle */}
                                    <div className="profile-circle circle-md">
                                        <span className="profile-icon icon-5">
                                            <img className="icon-5-img" src={this.state.data.profileIcon_3} alt="" />
                                        </span>
                                        <span className="profile-icon icon-6">
                                            <img className="icon-6-img" src={this.state.data.profileIcon_3} alt="" />
                                        </span>
                                        <span className="profile-icon icon-7">
                                            <img className="icon-7-img" src={this.state.data.profileIcon_3} alt="" />
                                        </span>
                                    </div>
                                    {/* Profile Circle */}
                                    <div className="profile-circle circle-sm">
                                        <span className="profile-icon icon-8">
                                            <img className="icon-8-img" src={this.state.data.profileIcon_4} alt="" />
                                        </span>
                                        <span className="profile-icon icon-9">
                                            <img className="icon-9-img" src={this.state.data.profileIcon_4} alt="" />
                                        </span>
                                    </div>
                                </div>
                                <img className="folder-img" src={this.state.data.folderImg_1} alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            {/* Content Inner */}
                            <div className="content-inner text-center pt-sm-4 pt-lg-0 mt-sm-5 mt-lg-0">
                                {/* Section Heading */}
                                <div className="section-heading text-center mb-3">
                                    <h2 className="text-white">{this.state.data.heading_1}<br /> {this.state.data.heading_1_span}</h2>
                                    <p className="text-white-50 d-none d-sm-block mt-4">{this.state.data.headingText_1}</p>
                                    <p className="text-white-50 d-block d-sm-none mt-4">{this.state.data.headingText_2}</p>
                                </div>
                                {/* Content List */}
                                <ul className="content-list text-left">
                                    {/* Single Content List */}
                                    {this.state.contentData_1.map((item, idx) => {
                                        return(
                                            <li key={`ct_${idx}`} className="single-content-list media py-2">
                                                <div className="content-icon pr-4">
                                                    <span className="color-2"><i className={item.iconClass} /></span>
                                                </div>
                                                <div className="content-text media-body">
                                                    <span className="text-white"><b>{item.title}</b><br />{item.text}</span>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                                <a href="/#" className="btn btn-bordered-white mt-4">{this.state.data.btnText}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ContentFive;