import React, { Component } from 'react';

const initData = {
    heading: "The world is searching.",
    heading_span: "We'll help you get discovered.",
    content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit nihil tenetur minus quidem est deserunt molestias accusamus harum ullam tempore debitis et, expedita, repellat delectus aspernatur neque itaque qui quod.",
    btnText_1: "Contact Us",
    btnText_2: "View Process"
}

class HeroFour extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: initData
        })
    }
    render() {
        return (
            <section id="home" className="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        {/* Welcome Intro Start */}
                        <div className="col-12 col-md-10">
                            <div className="welcome-intro text-center mt-0 py-5">
                                <h1 className="text-white">{this.state.data.heading} <br />{this.state.data.heading_span}</h1>
                                <p className="text-white my-4">{this.state.data.content}</p>
                                {/* Buttons */}
                                <div className="button-group">
                                    <a href="/#" className="btn btn-bordered-white">{this.state.data.btnText_1}</a>
                                    <a href="/#" className="btn btn-bordered-white d-none d-sm-inline-block">{this.state.data.btnText_2}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HeroFour;