import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/digimax-json-2/themeOneFooterSection";

class FooterTwo extends Component {
    state = {
        data: {},
        footerList_1: [],
        footerList_2: [],
        footerList_3: [],
        iconList: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    footerList_1: res.data.footerList_1,
                    footerList_2: res.data.footerList_2,
                    footerList_3: res.data.footerList_3,
                    iconList: res.data.iconList
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <footer className="section footer-area dark-bg">
                {/* Footer Top */}
                <div className="footer-top ptb_100">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-3">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Footer Title */}
                                    <h3 className="footer-title text-white text-uppercase mb-2">{this.state.data.footerTitle_1}</h3>
                                    <ul>
                                        {this.state.footerList_1.map((item, idx) => {
                                            return(
                                                <li key={`flo_${idx}`} className="py-2"><a className="text-white-50" href="/#">{item.text}</a></li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Footer Title */}
                                    <h3 className="footer-title text-white text-uppercase mb-2">{this.state.data.footerTitle_2}</h3>
                                    <ul>
                                        {this.state.footerList_2.map((item, idx) => {
                                            return(
                                                <li key={`flt_${idx}`} className="py-2"><a className="text-white-50" href="/#">{item.text}</a></li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Footer Title */}
                                    <h3 className="footer-title text-white text-uppercase mb-2">{this.state.data.footerTitle_3}</h3>
                                    <ul>
                                        {this.state.footerList_3.map((item, idx) => {
                                            return(
                                                <li key={`flth_${idx}`} className="py-2"><a className="text-white-50" href="/#">{item.text}</a></li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Footer Title */}
                                    <h3 className="footer-title text-white text-uppercase mb-2">{this.state.data.footerTitle_4}</h3>
                                    <p className="text-white-50 mb-2">{this.state.data.text}</p>
                                    {/* Social Icons */}
                                    <ul className="social-icons list-inline pt-2">
                                        {this.state.iconList.map((item, idx) => {
                                            return(
                                                <li key={`fi_${idx}`} className="list-inline-item px-1"><a className="text-white-50" href="/#"><i className={item.iconClass} /></a></li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Footer Bottom */}
                <div className="footer-bottom dark-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {/* Copyright Area */}
                                <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                                    {/* Copyright Left */}
                                    <div className="copyright-left text-white-50">© Copyrights 2020 Digimax All rights reserved.</div>
                                    {/* Copyright Right */}
                                    <div className="copyright-right text-white-50">Made with <i className="fas fa-heart" /> By <a className="text-white-50" href="/#">Themeland</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default FooterTwo;