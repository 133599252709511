import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/digimax-json-3/themeOneBlogSection";

class BlogSingle extends Component {
    state = {
        blogData: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    blogData: res.data.blogData
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <div className="row">
                {this.state.blogData.map((item, idx) => {
                    return(
                        <div key={`bdo_${idx}`} className="col-12 col-md-6">
                            {/* Single Blog */}
                            <div className="single-blog res-margin">
                                {/* Blog Thumb */}
                                <div className="blog-thumb">
                                    <a href="/#"><img src={item.image} alt="" /></a>
                                </div>
                                {/* Blog Content */}
                                <div className="blog-content">
                                    {/* Meta Info */}
                                    <ul className="meta-info d-flex justify-content-between">
                                        <li>By <a href="/#">{item.author}</a></li>
                                        <li><a href="/#">{item.date}</a></li>
                                    </ul>
                                    {/* Blog Title */}
                                    <h3 className="blog-title my-3"><a href="/#">{item.title}</a></h3>
                                    <p>{item.text}</p>
                                    <a href="/#" className="blog-btn mt-3">{item.btnText}</a>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default BlogSingle;